import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "job" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            date
            header {
              title
              subtitle
              description
            }
          }
        }
      }
    }
  }
`;

const JobPage = ({ data }) => {
  const pageContent = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { header } = pageContent;

  const heading = (
    <Heading>
      <h1>{header.title}</h1>
      <h3>{header.subtitle}</h3>
      <h3>{header.description}</h3>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title={pageContent.title} />
    </Layout>
  );
};

export default React.memo(JobPage);
