import React from "react";
import cx from "classnames";

import styles from "./Heading.module.scss";

const Heading = ({ children, className }) => {
  return <div className={cx(styles.container, className)}>{children}</div>;
};

export default React.memo(Heading);
